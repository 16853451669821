import axios from 'axios'
import store from '../store'

export default {
  getSession (user = null) {
    const u = JSON.parse(localStorage.getItem('localUser'))
    if(u !== null){
      return this.getUserInfo(u)
    }else{
      return this.getUserInfo(user)
    }
  },
  getUserInfo (user = null) {
    return axios.post('api/User/UserLogin', user)
      .then((r) => {
        store.dispatch('loginModule/login', r.data)
        localStorage.setItem('localUser', JSON.stringify(user))
        localStorage.setItem('token', JSON.stringify(r.data.token))
      })
      .catch((e) => {
        console.log(e)
      })
  }
}
