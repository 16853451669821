<template>
  <v-form v-model="isDataValid" ref="Login" enctype="multipart/form-data">
    <v-card :loading="isLoading" class="mx-auto my-12" max-width="400" :disabled="isLoading"
      style="background-color: #d4e3fb;">
      <v-card-text>
        <v-row class="mx-10">
          <v-img :src="require('@/assets/logo.png')"></v-img>
        </v-row>
        <v-row class="mx-12">
          <v-text-field v-on:keyup.enter="Login()" label="Usuario" required v-model="UserModel.userName"></v-text-field>
          <v-text-field type="password" v-on:keyup.enter="Login()" label="Contraseña" required
            v-model="UserModel.password"></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col style="text-align:center;">
          <v-btn @click.stop="Login()" :disabled="!isDataValid" color="#5031b9" depressed>
            <span style="color: white;">Entrar</span>
          </v-btn>
        </v-col>
      </v-card-actions>
      <div>&nbsp;</div>
    </v-card>
    <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType"
            @click.stop="closeDialog">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { loading, showMessage, rules } from '../mixins'
import store from '../store'
import authService from '../helpers/authService'

export default {
  name: 'Login',
  mixins: [loading, showMessage, rules],
  data: () => ({
    isDataValid: false,
    UserModel: {},
    isConfirmationOpen: false,
    message: '',
    buttonType: 'primary'
  }),
  methods: {
    closeDialog () {
      this.clearForm()
      this.isConfirmationOpen = false
    },
    clearForm () {
      this.UserModel = {}
      this.$refs.Login.resetValidation()
    },
    Login () {
      this.changeLoadingStatus(true)
      authService.getSession(this.UserModel).then(() => {
        const userInfo = store.getters['loginModule/userInfo']
        if (!userInfo && !userInfo.isAuthenticated) {
          this.isConfirmationOpen = true
          this.buttonType = 'error'
          this.message = 'Usuario y/o contraseña incorrecto(s) !!'  
        } else {
          this.$router.push({ name: 'home' }).catch(()=>{})
        }
      }).catch((e) => {
        this.showHttpErrorMessage(e)
        this.isConfirmationOpen = true
        this.buttonType = 'error'
        this.message = 'Usuario y/o contraseña incorrecto(s) !!'
      }).finally(() => {
        this.changeLoadingStatus(false)
      })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>
