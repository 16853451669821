import { mapActions, mapGetters } from 'vuex'

const showMessage = {
  methods: {
    ...mapActions('snackbarModule', ['showMessage', 'showHttpErrorMessage'])
  }
}

const loading = {
  computed: {
    ...mapGetters(['isLoading'])
  },
  methods: {
    ...mapActions(['changeLoadingStatus'])
  }
}

const rules = {
  data: () => ({
    rules: {
      required: (v, message) => !!v || (message || 'Campo requerido'),
      maxCharacters: (v, max, message) => (v?.length <= max) || (message || `Max ${max} characters`),
      minCharacters: (v, min, message) => (v?.length > min) || (message || `Min ${min} characters`),
      minNumber: (v, min, message) => (v > min) || (message || `La cantidad debe ser mayor a lo registrado(${min}) previamente`),
      requiredObjectWithId: (v, message) => !!(v?.id) || (message || 'Campo requerido'),
      requiredArray: (v, message) => !!(v?.length > 0) || (message || 'Campo requerido'),
      positiveInteger: (v, message) => (/^$|^\d+$/.test(v) && v > 0) || (message || 'Invalid value'),
      email: (v, message) => /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2}|com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)$/.test(v) || (message || 'Email no valido'),
      number: (v, message) => !isNaN(v) || (message || 'Invalid number'),
      noSpaces: (v, message) => !/\s/.test(v) || (message || 'No spaces allowed'),
      noRequired: (v) => !!(true) || (''),
    }
  })
}

const userInfo = {
  computed: {
    ...mapGetters('loginModule', ['userInfo'])
  },
  methods: {
    checkRoles (role) {
      return this.userInfo.idUserType === role
    }
  }
}

export { loading, showMessage, rules, userInfo }
