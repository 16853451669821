<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <small style="color: white">{{ userInfo.userName }}</small>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { userInfo } from '../../mixins'
export default {
  name: 'UserInformation',
  data: () => ({
  }),
  mixins: [userInfo]
}
</script>

<style>

</style>
