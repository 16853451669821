<template>
  <v-container >
    <v-card :loading="isLoading">
        <v-card-title>Home</v-card-title>
        <v-card-subtitle>{{ userInfo.name + ' ' + userInfo.lastName }}</v-card-subtitle>
        <v-card-text>
        </v-card-text>
        <v-card-actions style="padding-bottom:80px;">
          <v-spacer />
          <v-img :src="require('@/assets/pipa.png')"  max-width="800px"></v-img>
          <v-spacer />
        </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>

import { userInfo, loading } from '../mixins'

export default {
  name: 'HomePage',
  data: () => ({
    items: []
  }),
  mixins: [userInfo, loading],
  methods: {
  },
  mounted() {
  },
}
</script>
<style>
</style>
