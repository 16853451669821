<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row justify="center" align="center" style="height: 400px;">
          <v-col cols="12" class="text-center">
            <p class="headline text--secondary">Pagina no encontrada</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style >
</style>
