<template>
  <v-app>
    <v-navigation-drawer v-model="isDrawerOpen" app clipped dark v-if="userInfo.isAuthenticated">
      <user-information></user-information>
      <v-divider></v-divider>
      <menu-list></menu-list>
    </v-navigation-drawer>
    <v-container>
      <v-app-bar app clipped-left color="#948af0">
        <v-col>
          <v-row>
            <v-app-bar-nav-icon @click.stop="isDrawerOpen = !isDrawerOpen"
              v-if="userInfo.isAuthenticated" style="color:#3c2f88;"></v-app-bar-nav-icon>
            <h2 style="margin-top:5px; color:#3c2f88;">&nbsp;&nbsp;{{ userInfo.name }} {{ userInfo.lastName }}</h2>
          </v-row>
        </v-col>
        <v-col align="right">
          <v-img style="height: 65px;" :src="require('@/assets/logo1.png')" max-width="350px"></v-img>
        </v-col>
      </v-app-bar>
    </v-container>
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </v-slide-y-transition>
      <v-snackbar v-model="snackbar.visible" :bottom="snackbar.y === 'bottom'" :left="snackbar.x === 'left'"
        :multi-line="snackbar.mode === 'multi-line'" :right="snackbar.x === 'right'" :timeout="snackbar.timeout"
        :top="snackbar.y === 'top'" :vertical="snackbar.mode === 'vertical'">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" @click="hideMessage" text small v-bind="attrs">
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer dark>
      <v-card class="flex">
        <v-card-text class="py-2 white--text text-center">
          Copyright © 2024 ☺ RIO LIMPIO - All Rights Reserved
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuList from './components/navigationDrawer/MenuList.vue'
import UserInformation from './components/navigationDrawer/UserInformation.vue'
import { userInfo, loading } from './mixins'

export default {
  name: 'App',
  components: { MenuList, UserInformation },
  data: () => ({
    isDrawerOpen: false,
    setValue: false,
  }),
  mixins: [userInfo, loading],
  computed: {
    ...mapGetters('snackbarModule', ['snackbar']),
  },
  methods: {
    ...mapActions('snackbarModule', ['hideMessage']),
  },
  created () {
    
  }
}
</script>

<style>

@font-face {
    font-family: 'OCR-B 10 BT';
    font-style: normal;
    font-weight: 400;
    src: local('OCR-B 10 BT'), url('./assets/fonts/OCR-b.woff') format('woff');
}

@font-face {
    font-family: 'OCR A Std';
    font-style: normal;
    font-weight: 400;
    src: local('OCR A Std'), url('./assets/fonts/OCRAStd.woff') format('woff');
}

@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 400;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 59 Compressed.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 250;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 29 Compressed Ultra Light.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 275;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 39 Compressed Thin.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 300;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 49 Compressed Light.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 450;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 69 Compressed Medium.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 700;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 79 Compressed Bold.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica Compressed';
    font-style: normal;
    font-weight: 900;
    src: local('Neue Helvetica Compressed'), url('./assets/fonts/Neue Helvetica 99 Compressed Black.woff') format('woff');
}
@font-face {
    font-family: 'Neue Helvetica';
    font-style: normal;
    font-weight: 800;
    src: local('Neue Helvetica'), url('./assets/fonts/Neue Helvetica 89 Compressed Heavy.woff') format('woff');
}

</style>
