<template></template>

<script>
import { loading } from '../mixins'
import store from '../store'

export default {
  name: 'Logout',
  mixins: [loading],
  data: () => ({
  }),
  methods: {
    logout () {
      this.changeLoadingStatus(true)
      store.dispatch('loginModule/login', null)
      store.dispatch('loginModule/logout', null)
      localStorage.removeItem("localUser")
      localStorage.removeItem("token")
      this.$router.push({ name: 'login' })
      this.changeLoadingStatus(false)
    }
  },
  mounted () {
    this.logout()
  }
}
</script>
